<template>
	<!-- Header + Buttons -->
	<header>
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="intro-text">
						<span class="name pre-title" style="font-weight: normal;font-size: 3em;textcolor: @col-conf-pretitle;" v-if="getArgument(header.preTitle)" v-html="getArgument(header.preTitle)"></span>
						<span class="name" style="font-size: 3.5em;" v-if="getArgument(header.title)" v-html="getArgument(header.title)"></span>
						<!-- <span class="name post-title" style="font-size: 3em;" v-if="getArgument(header.postTitle)" v-html="getArgument(header.postTitle)"></span> -->
						<!-- <div class="name" v-if="getArgument(header.title)"><dynamic :template="getArgument(header.title)"/></div>  -->
						<!-- <hr class="star-light"> -->
						<faruler></faruler>
						<div class="skills">
							<span v-if="getArgument(header.dates)" v-html="getArgument(header.dates)"></span><br>
							<!-- <span v-if="getArgument(header.location)" v-html="getArgument(header.location)"></span><br> -->
							<!-- <span class="message" v-if="getArgument(header.message)" v-html="getArgument(header.message)"></span><br> -->

								<!-- BUTTONS -->
							<div style="margin-top: 1em">
								<a v-for="btn of visibleButtons" style="margin-left: 4px;" :key="btn.key" tabindex="0" class="btn btn-outline" :target="getArgument(btn.target).includes('http')?'_blank':'_self'" :href="getArgument(btn.target)"><span v-if="getArgument(btn.icon)"><i :class="btn.icon"></i>&nbsp;</span>{{getArgument(btn.text)}}</a>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</header>

</template>

<script>

	import faruler from './fa-ruler.vue';
	import dynamic from './dynamicHTML.vue';

	export default {
		name: 'mainHeader',

		props: {
			header: Object,
			getArgument: {
				type: Function,
				default: (a) => {
					if ( a === undefined ) return null;
					if ( typeof a == "object") return a.value
					return a
				}
			},
			dispNow: {
				type: Function,
				default: () => true
			}
		},
		components: {
			faruler: faruler,
			dynamic: dynamic
		},
		computed: {
			visibleButtons: function(){
				let res = [];
				for (let btn of this.header.buttons){
					if (this.dispNow(btn)) res.push(btn);
				}
				return res;
			}
		},
		methods: {

		}
	}

</script>

<style lang="less">
	@import '../../style-variables.less';

	header {
		text-align: center;
		color: #fff;
		background: #2c3e50;
		background: linear-gradient(      rgba(5, 5, 5, 0.15),       rgba(0, 0, 0, 0.45)    ), @cover no-repeat center;
		//background-size: cover;
	}

	header .container {
		padding-top: 100px;
		padding-bottom: 50px;
	}

	header img {
		//position: absolute;
		//width: 100%;
		//height: 100%;
		//max-width: 100%;
		display: block;
		margin: 0 auto 20px;
	}

	header .intro-text .name {
		display: block;
		//color: #10465f;
		color: @col-conf-title;
		// text-transform: uppercase;
		// font-family: FormataBold, Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
		font-family: Montserrat, "Helvetica Neue",Helvetica,Arial,sans-serif;
		font-size: 3em;
		font-weight: 700;
	}


	header .intro-text .pre-title {
		color: @col-conf-pretitle;//rgb(0,105,180);
	}


	header .intro-text .skills {
		color: @col-conf-date;
		font-size: 1.25em;
		//font-weight: 300;
		font-weight: 600;
	}

	header span.message{
		display: block;
		color: @link-light;
		font-weight: bold;
		margin-top: 1em;
		font-size: 1.3em;
	}

	header .btn:hover{
		color: black !important;
	}

	@media(min-width:@layoutThreshold) {
		header .container {
			padding-top: 200px;
			padding-bottom: 250px;
		}

		header .intro-text .name {
			font-size: 3.75em;
		}

		header .intro-text .skills {
			font-size: 1.8em;
		}
	}

	@media(max-width:@layoutThreshold) {

		header {
			background: linear-gradient(@col-back-dark-g1,@col-back-dark-g2) !important;
			// background: linear-gradient(@col-header-back-fade-g1, @col-header-back-fade-g2), @cover no-repeat center !important;
			// background-size: cover !important;

			.intro-text, .name, .pre-title, .skills {
				color: @col-text-light !important;
			}

			.btn.btn-outline {
				color: @col-text-light !important;
				border-color: @col-text-light !important;
				font-size: 17px !important;
				background: @col-text-light;
				backdrop-filter: blur(5px);
				background-color: @col-btn-back;
			}

			.btn.btn-outline:hover, .btn.btn-outline:active, .btn.btn-outline:focus  {
				color: @col-text-light !important;
				background-color: @col-btn-hover-back !important;
				// background-color: @link-dark !important;
				//border-color: @link-light !important;
			}
		}
	}

</style>
