


export var config = {

	utcoffset: "+0200",
	testLocations: ['localhost','5gsummit.org/dresden123'],
	startTime: Date.now(),
	intervalUpdateTimeLogicS: 10,
	intervalReloadContentsMin: 30,
	limitedCountryCodes: ["CN","IR","KP","CU","ID","SY","SD"],
	//faRulerSymbol: 'fa fa-wifi',
	//faRulerSymbol: 'fa fa-calculator',
	//faRulerSymbol: 'fa fa-bicycle',
	//faRulerSymbol: 'fa fa-hashtag',	
	//faRulerSymbol: 'fa fa-star',
	//faRulerSymbol: 'fa fa-square',
	//faRulerSymbol: 'fa fa-circle',
	//faRulerSymbol: 'fa fa-spinner',
	//faRulerSymbol: 'fa fa-asterisk',
	faRulerSymbol: 'fa fa-chevron-up',

	// passwordHash: "37f99c98160b991b9a81925f8df132ca",
	// passwordCookieExpirationDays: 30,

	// currentScheduleItemLinksTo: "#about",

	gotoStartButtonTime: "10.5.2023, 8:00:00",
}
