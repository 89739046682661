export var tpcmembers = `
<p>
<table style="font-size:1.2em;">
  <tbody>
    <tr>
      <td>Gerhard Bauch (Hamburg University of Technology)</td>
    </tr>
    <tr>
      <td>Emil Björnson (KTH Royal Institute of Technology)</td>
    </tr>
    <tr>
      <td>Mario Castañeda (Munich Research Center, Huawei Technologies Duesseldorf GmbH)</td>
    </tr>
    <tr>
      <td>Renato Cavalcante (Fraunhofer Heinrich Hertz Institute)</td>
    </tr>
    <tr>
      <td>Zheng Chen (Linköping University)</td>
    </tr>
    <tr>
      <td>Pascal Chevalier (CNAM)</td>
    </tr>
    <tr>
      <td>Rodrigo de Lamare (Pontifical Catholic University of Rio de Janeiro)</td>
    </tr>
    <tr>
      <td>Armin Dekorsy (University of Bremen)</td>
    </tr>
    <tr>
      <td>Christian Deppe (Technical University of Braunschweig)</td>
    </tr>
    <tr>
      <td>Giuseppe Durisi (Chalmers University of Technology)</td>
    </tr>
    <tr>
      <td>Robert Fischer (Ulm University)</td>
    </tr>
    <tr>
      <td>Pratik Ghate (FormFactor, Inc)</td>
    </tr>
    <tr>
      <td>Maxime Guillaud (INRIA)</td>
    </tr>
    <tr>
      <td>Vahid Jamali (Technical University of Darmstadt)</td>
    </tr>
    <tr>
      <td>Eduard Jorswieck (Technical University of Braunschweig)</td>
    </tr>
    <tr>
      <td>Markku Juntti (University of Oulu)</td>
    </tr>
    <tr>
      <td>Muhammad Kazmi (Ericsson)</td>
    </tr>
    <tr>
      <td>Anja Klein (Technical University of Darmstadt)</td>
    </tr>
    <tr>
      <td>Gerhard Kramer (Technical University of Munich)</td>
    </tr>
    <tr>
      <td>Volker Kuehn (University of Rostock)</td>
    </tr>
    <tr>
      <td>Erik G. Larsson (Linköping University)</td>
    </tr>
    <tr>
      <td>Didier Le Ruyet (CNAM)</td>
    </tr>
    <tr>
      <td>Michael Lentmaier (Lund University)</td>
    </tr>
    <tr>
      <td>Israel Leyva-Mayorga (Aalborg University)</td>
    </tr>
    <tr>
      <td>Gianluigi Liva (German Aerospace Center (DLR))</td>
    </tr>
    <tr>
      <td>Michail Matthaiou (Queen's University Belfast)</td>
    </tr>
    <tr>
      <td>Christoph Mecklenbräuker (Vienna University of Technology)</td>
    </tr>
    <tr>
      <td>Michael Meyer (Ericsson Research)</td>
    </tr>
    <tr>
      <td>Khac-Hoang Ngo (Chalmers University of Technology)</td>
    </tr>
    <tr>
      <td>Tobias Oechtering (KTH Royal Institute of Technology)</td>
    </tr>
    <tr>
      <td>Samir Perlaza (INRIA)</td>
    </tr>
    <tr>
      <td>Stephan Pfletschinger (Offenburg University of Applied Sciences)</td>
    </tr>
    <tr>
      <td>Ignacio Santamaria (University of Cantabria)</td>
    </tr>
    <tr>
      <td>Laurent Schmalen (Karlsruhe Institute of Technology (KIT))</td>
    </tr>
    <tr>
      <td>Robert Schober (Friedrich-Alexander University Erlangen-Nuremberg)</td>
    </tr>
    <tr>
      <td>Aydin Sezgin (RUB)</td>
    </tr>
    <tr>
      <td>Dirk Slock (EURECOM)</td>
    </tr>
    <tr>
      <td>Slawomir Stanczak (Technical University of Berlin)</td>
    </tr>
    <tr>
      <td>Stephan ten Brink (University of Stuttgart)</td>
    </tr>
    <tr>
      <td>Reiner Thomä (Ilmenau University of Technology)</td>
    </tr>
    <tr>
      <td>Andrea Tonello (University of Klagenfurt)</td>
    </tr>
    <tr>
      <td>Sander Wahls (Karlsruhe Institute of Technology (KIT))</td>
    </tr>
    <tr>
      <td>Tobias Weber (University of Rostock)</td>
    </tr>
    <tr>
      <td>Dirk Wübben (University of Bremen)</td>
    </tr>
    <tr>
      <td>Thomas Zemen (AIT Austrian Institute of Technology GmbH)</td>
    </tr>
    <tr>
      <td>Abdelhak Zoubir (Technical University of Darmstadt)</td>
    </tr>
  </tbody>
</table>
</p>
`
