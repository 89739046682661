export var program = `

<style>

table {
  border: 2px solid rgb(51, 51, 51);
  border-radius: 5px;
  padding: 2px;
  font-size: 1.2em;
  width: 100%;
}

th {
  font-weigth: bold;
  border-bottom: 1px solid gray;
  background-color: rgb(14, 83, 116);
  color: white;
  text-align: center;
}

td {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: top;
}

th.date, td.date {
  width: 18%;
  text-align: center;
}

th.activity, td.activity {
  width: 82%;
}

ul {
  list-style: inside;
  list-style-type: square;
  padding-left: 0;
}​

</style>

<h3>Sunday, March 17, 2024</h3>

<table>
  <tr>
    <th class="Time">Time</th>
    <th class="activity">Event</th>
  </tr>
  <tr>
    <td class="date">12:30 - 13:00</td>
    <td class="activity">Registration & Welcome Coffee</td>
   </tr>
   <tr>
     <td class="date">13:00 - 13:30</td>
     <td class="activity">
      <strong>Track on 6G Research in Germany</strong><br>
      <em>Introduction to 6G Platform Germany - The German Platform for Future Communications Technology and 6G</em><br>
      Norman Franchi (FAU Erlangen-Nürnberg)
     </td>
   </tr>
   <tr>
     <td class="date">13:30 - 14:30</td>
     <td class="activity">
      <strong>Track on 6G Research in Germany</strong><br>
      <em>Reconfigurable Intelligent Surfaces (RIS) for 6G: From Concepts to Applications and to Experiments</em><br>
      Aydin Sezgin (Ruhr-Universität Bochum)
      </td>
   </tr>
   <tr>
     <td class="date">14:30 - 15:30</td>
     <td class="activity">
      <strong>Track on 6G Research in Germany</strong><br>
      <em>Some Highlights from the 6G Research and Innovation Cluster</em><br>
      Slawomir Stanczak (TU Berlin, Fraunhofer HHI)
      </td>
   </tr>
   <tr>
    <td class="date">15:30 - 16:00</td>
    <td class="activity">Coffee Break</td>
   </tr>
   <tr>
     <td class="date">16:00 - 17:00</td>
     <td class="activity">
      <strong>Track on 6G Research in Germany</strong><br>
      <em>Research Highlights from 6G-life</em><br>
      Frank Fitzek (TU Dresden)
      </td>
   </tr>
   <tr>
     <td class="date">17:00 - 18:00</td>
     <td class="activity">
      <strong>Track on 6G Research in Germany</strong><br>
      <em>Open6GHub Germany - Multiband Massive-MIMO Testbed</em><br>
      Norman Franchi & Lukas Witte (FAU Erlangen-Nürnberg)
      </td>
   </tr>
   <tr>
    <td class="date">18:00 - 20:30</td>
    <td class="activity">Welcome Reception</td>
   </tr>
</table>

<br>
<h3>Monday, March 18, 2024</h3>

<table>
  <tr>
    <th class="date">Time</th>
    <th class="activity">Event</th>
  </tr>
  <tr>
    <td class="date">08:30 - 09:00</td>
    <td class="activity">Morning Coffee</td>
   </tr>
   <tr>
     <td class="date">09:00 - 09:30</td>
     <td class="activity">
      <strong>Conference Opening</strong><br>
      <em>Are there Remaining 6G Research Opportunities?</em><br>
      Gerhard Fettweis & Rafael Schaefer (TU Dresden)
      </td>
   </tr>
   <tr>
     <td class="date">09:30 - 10:00</td>
     <td class="activity">
      <strong>Keynote</strong><br>
      <em>Antenna Systems for the 5th and 6th Generation of Mobile Networks</em><br>
      Philipp Gentner (Ericsson Antenna Systems)
      </td>
   </tr>
   <tr>
    <td class="date">10:00 - 10:30</td>
    <td class="activity">Coffee Break</td>
   </tr>
   <tr>
     <td class="date">10:30 - 11:45</td>
     <td class="activity">
      <strong>Session 1: Wireless Channel Modelling and Prediction</strong><br>
      <br>
      <strong>Invited Talk</strong><br>
      <em>From Technology to Products: The Role of Standardization</em><br>
      Michael Meyer (Ericsson Eurolab)<br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>Wireless Channel Prediction via Gaussian Mixture Models</em> <a href="papers/1570979639.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Nurettin Turan</u>, Benedikt Böck, Kai Jie Chan, Benedikt Fesl, Friedrich Burmeister, Michael Joham, Gerhard P. Fettweis, and Wolfgang Utschick</li>
      <li><em>Modelling of Wireless Links with Reconfigurable Intelligent Surfaces Using Multiport Network Analysis</em> <a href="papers/1570977359.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Josef A. Nossek</u>, Dominik Semmler, Michael Joham, and Wolfgang Utschick</li>
      <li><em>Insights into the Near-Field Characteristics of mm-wave Massive MIMO Arrays using EM Lagrangian Density and Poynting Vector</em> <a href="papers/1570978476.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Debdeep Sarkar</u></li>
      </ul>
      </td>
   </tr>
   <tr>
    <td class="date">12:00 - 13:00</td>
    <td class="activity">Lunch Break</td>
   </tr>
   <tr>
     <td class="date">13:00 - 14:00</td>
     <td class="activity">
      <strong>Session 2-A: Security & Privacy</strong><br>
      <br>
      <strong>Invited Talk</strong><br>
      <em>Smart, Adaptive, Context Aware Physical Layer Security for 6G: Announcing the First PLS Security Challenge</em><br>
      Arsenia Chorti (ENSEA, ETIS Lab)<br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>Secret Key Generation Rates for Line of Sight Multipath Channels in the Presence of Eavesdroppers</em> <a href="papers/1570979733.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Amitha Prakasha Mayya</u>, Arsenia Chorti, Rafael F. Schaefer, and Gerhard P. Fettweis</li>
      <li><em>Comparison of Optimization Criteria for Preprocessing for Physical-Layer Security in Multi-Carrier-THz-MIMO Systems</em> <a href="papers/1570971284.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Rebekka Schulz</u>, and Robert F.H. Fischer</li>
      </ul>
      </td>
   </tr>
   <tr>
     <td class="date">14:00 - 14:30</td>
     <td class="activity">
      <strong>Session 2-B: One-bit Quantization</strong><br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>Time Instance Zero-Crossing Precoding for mmWave Channels Employing 1-bit Quantization and Oversampling</em> <a href="papers/1570979879.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Diana M. V. Melo</u>, Lukas T. N. Landau, and Rodrigo C. de Lamare</li>
      <li><em>On the Timing Synchronization for Receivers with Temporally Oversampled 1-bit Quantization</em> <a href="papers/1570979765.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Stephan Zeitz</u>, Daniel Seifert, Florian Roth, Martin Schlüter, Meik Dörpinghaus, and Gerhard P. Fettweis</li>
      </ul>
      </td>
   </tr>
   <tr>
     <td class="date">14:30 - 15:00</td>
     <td class="activity">
      <strong>Keynote</strong><br>
      <em>Shaping the Future of Communication: An In-depth Look into Directional Transmission Systems</em><br>
      Luca Steinweg & Raimon Göritz (Rohde & Schwarz)
      </td>
   </tr>
   <tr>
    <td class="date">15:00 - 16:30</td>
    <td class="activity">Extended Coffee Break: <strong>Demo & Poster Session</strong></td>
   </tr>
   <tr>
     <td class="date">16:30 - 17:45</td>
     <td class="activity">
      <strong>Session 3: Signal Processing Techniques</strong><br>
      <br>
      <strong>Invited Talk</strong><br>
      <em>Low Earth Orbit Satellite Constellations: Communications and Distributed Learning</em><br>
      Bho Matthiesen (Universität Bremen)<br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>PRACH Signal Design and Detection for LEO Satellite Systems with Imperfect UE Positioning</em> <a href="papers/1570976553.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Màrius Caus</u> and Musbah Shaat</li>
      <li><em>Modified Hierarchical Modulation for Hybrid RF-FSO Satellite Communication</em> <a href="papers/1570977183.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Marc Jovan Moreno Amay</u>, Joan Bas, Miguel Angel Ángel Vazquez, and Ana Pérez-Neira</li>
      <li><em>Real-World OTFS Channel Estimation Performance Evaluation on mmWave Vehicular Channels</em> <a href="papers/1570979789.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Roman Maršálek</u>, Jiri Blumenstein, Josef Vychodil, Tomas Mikulasek, Peter Jung, and Andreas Pfadler</li>
      </ul>
      </td>
   </tr>
   <tr>
    <td class="date">19:00 - 23:00</td>
    <td class="activity">Social Event at Schloss Albrechtsberg</td>
   </tr>
</table>

<br>
<h3>Tuesday, March 19, 2024</h3>

<table>
  <tr>
    <th class="date">Time</th>
    <th class="activity">Event</th>
  </tr>
  <tr>
    <td class="date">08:30 - 09:00</td>
    <td class="activity">Morning Coffee</td>
   </tr>
   <tr>
     <td class="date">09:00 - 10:30</td>
     <td class="activity">
      <strong>Session 4: Beamforming & Antenna Arrays</strong><br>
      <br>
      <strong>Invited Talk</strong><br>
      <em>Energy-efficient Radio Unit Design for the Next Generation of MIMO Systems</em><br>
      Stefan Wesemann (Nokia Bell Labs)<br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>Improving the Spatial Correlation Characteristics of Antenna Arrays using Linear Operators and Wide-band Modelling</em> <a href="papers/1570979698.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Marc Miranda</u>, Sebastian Semper, Michael Döbereiner, and Reiner S. Thomä</li>
      <li><em>Clustered Robust Linear Precoding for Cell-Free MU-MIMO Systems</em> <a href="papers/1570976804.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      Andre R. Flores, <u>Rodrigo C. de Lamare</u>, and Kumar Vijay Mishra</li>
      <li><em>Joint Beamforming and Trajectory Optimization for UAV-Aided ISAC with Dipole Antenna Array</em> <a href="papers/1570979702.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Mustafa Burak Yilmaz</u>, Lin Xiang, and Anja Klein</li>
      <li><em>Dual-band Endfire Phased Array Antenna for mmWave 5G NR Bands Applications</em> <a href="papers/1570979859.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      Ali Zidour, Mouloud Ayad, <u>Mohammad Alibakhshikenari</u>, Boumediene Guenad, Mohammad Soruri, and Lida Kouhalvandi</li>
      </ul>
      </td>
   </tr>
   <tr>
    <td class="date">10:30 - 11:30</td>
    <td class="activity">Extended Coffee Break: <strong>Demo & Poster Session</strong></td>
   </tr>
   <tr>
     <td class="date">11:30 - 12:45</td>
     <td class="activity">
      <strong>Session 5: Networking</strong><br>
      <br>
      <strong>Invited Talk</strong><br>
      <em>Data-driven Modelling and Optimization of Green Future Mobile Networks: From Machine Learning to Generative AI</em><br>
      Antonio De Domenico (Huawei)<br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>Predictive Handover Optimization</em> <a href="papers/1570977722.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Vahid Rajabi</u>, Jochen Fink, Martin Kasparick, and Slawomir Stanczak</li>
      <li><em>Distributed Fixed-Point Algorithms for Dynamic Convex Optimization over Decentralized and Unbalanced Wireless Networks</em> <a href="papers/1570981731.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Navneet Agrawal</u>, Renato L. G. Cavalcante, and Slawomir Stanczak</li>
      <li><em>A New Spatio-Temporal Model for Data Rate Distributions in Mobile Networks</em> <a href="papers/1570979776.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Florian Gast</u>, Meik Dörpinghaus, Florian Roth, and Gerhard P. Fettweis</li>
      </ul>
      </td>
   </tr>
   <tr>
    <td class="date">12:45 - 13:45</td>
    <td class="activity">Lunch & Coffee Break</td>
   </tr>
   <tr>
    <td class="date">13:45 - 14:15</td>
    <td class="activity"><strong>Demo & Poster Session</strong></td>
   </tr>
   <tr>
     <td class="date">14:15 - 15:45</td>
     <td class="activity">
      <strong>Session 6: Machine Learning</strong><br>
      <br>
      <strong>Invited Talk</strong><br>
      <em>Toward Digital Twin Networks: Tools and Early Results</em><br>
      Fayçal Aït Aoudia (NVIDIA)<br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>GAN-based Massive MIMO Channel Model Trained on Measured Data</em> <a href="papers/1570977206.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Florian Euchner</u>, Janina Sanzi, Marcus Henninger, and Stephan ten Brink</li>
      <li><em>Variational Autoencoder for Channel Estimation: Real-World Measurement Insights</em> <a href="papers/1570976607.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Michael Baur</u>, Benedikt Böck, Nurettin Turan, and Wolfgang Utschick</li>
      <li><em>Channel Estimation and Equalization for SC-FDMA Using Machine Learning</em> <a href="papers/1570979835.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      Pouya Fakharizadeh, <u>Ömer Karakas</u>, Christos Anastasios Bovolis, Marco Breiling, and Wolfgang Gerstacker</li>
      <li><em>Loss Design for Single-carrier Joint Communication and Neural Network-based Sensing</em> <a href="papers/1570979665.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Charlotte Muth</u>, Benedikt Geiger, Daniel Gil Gaviria, and Laurent Schmalen</li>
      </ul>
      </td>
   </tr>
   <tr>
    <td class="date">15:45 - 16:15</td>
    <td class="activity">Coffee Break</td>
   </tr>
   <tr>
     <td class="date">16:15 - 17:00</td>
     <td class="activity">
      <strong>Session 7: Resource Allocation & Scheduling</strong><br>
      <br>
      <strong>Paper Presentations</strong><br>
      <ul>
      <li><em>Real-Time Algorithms for Combined eMBB and URLLC Scheduling</em> <a href="papers/1570979938.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Tano Bischoff</u>, Martin Kasparick, Ehsan Tohidi, and Slawomir Stanczak</li>
      <li><em>Improving NOMA Performance by Application of Autoencoders and Equidistant Power Allocation</em> <a href="papers/1570975367.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      Matthias Hummert, <u>Niklas Bulk</u>, Carsten Bockelmann, Dirk Wübben, and Armin Dekorsy</li>
      <li><em>Duality-Based Joint Clustering and Precoding for Cell-Free Distributed MIMO</em> <a href="papers/1570979743.pdf" target="_blank"><i aria-hidden="true" class="fa">&#xf1c1</i></a><br>
      <u>Martin Schubert</u>, Ronald Boehnke, and Wen Xu</li>
      </ul>
      </td>
   </tr>
   <tr>
    <td class="date">17:00 - 17:30</td>
    <td class="activity"><strong>Presentation of Best Paper Award & Conference Closing</strong></td>
   </tr>
</table>

`