<template>

	<!-- Video Modal -->
	<div id="video-details" v-if="showmodal" class="modal fade in">
		<div ref="modal-backdrop" class="modal-backdrop fade" @click="closeModal()"></div>
		<div class="modal-container">
			<div class="modal-dialog bio-dialog video-modal" role="dialog">
				<div ref="modal-dialog" class="modal-content">

					<div class="scrollable">

						<div class="close-modal" @click="closeModal()">
							<div class="box">
								<div class="lr">
									<div class="rl">
									</div>
								</div>
							</div>
						</div>

						<div class="modal-body" style="padding:10px;">
							<!-- <h3>Teaser&nbsp;+ Full&nbsp;Video</h3> -->
							<div v-if="g.unlocked">
								<div class="title" style="display: flex; flex-direction: row; align-items: flex-start;"><div style="flex: 10 1 auto;" v-html="title"></div></div>
								<div class="time">{{time}}</div>

								<div class="center-content">
									<div style="width: 100%; max-width: 45em;">
										<div class="info">
											<div class="text">
												<div class="presenter" v-if="name" v-html="name"></div>
												<div class="aff" v-if="affiliation" v-html="affiliation"></div>
											</div>
											<div class="logo" v-if="logo"><img :src="'img/sponsors/' + logo"></div>
										</div>
										<div class="language" v-show="lang && lang=='de'">German</div>
										<div class="language" v-show="lang && lang=='de/en'">German with English subtitles</div>
										<!-- Teaser -->
										<h3 v-if="(teaser && !g.limitedCountry) || (teaserSelfHosted && g.limitedCountry)">Teaser:</h3>
										<!-- Vimeo Teaser -->
										<div v-if="showmodal && teaser && !g.limitedCountry" style="width: 100%;">
											<div style="position: relative; overflow:hidden; padding-top: 56.25%; width: 100%">
												<iframe
													style="position:absolute;top:0;left:0;width:100%;height:100%"
													v-if="showmodal && teaser"
													:src="'https://player.vimeo.com/video/' + teaser"
													frameborder="0" allow="autoplay; fullscreen"
													allowfullscreen></iframe>
											</div>
										</div>
										<!-- Selfhosted Teaser -->
										<video v-if="showmodal && g.limitedCountry && teaserSelfHosted" width="100%" controls>
											<source :src="'https://www.vodafone-chair.org/media/5gsummit2020/'+teaserSelfHosted" type="video/mp4">
										</video>

										<h3 v-if="vimeo || ieeeID || selfHostedFilename">Full Video:</h3>
										<!-- FROM CHINA -->
											<!-- IEEE -->
											<iframe v-if="showmodal && g.limitedCountry && ieeeID" class="ieee-stream" :src="'https://ieeetv.ieee.org/player/embed_play/' + ieeeID + '/auto'" allowfullscreen frameborder="0" scrolling="no"></iframe>
											<!-- Self Hosted -->
											<video v-if="showmodal && g.limitedCountry && selfHostedFilename" width="100%" controls>
												<source :src="'https://www.vodafone-chair.org/media/5gsummit2020/'+selfHostedFilename" type="video/mp4">
											</video>
										<!-- Vimeo Full Video -->
										<div v-if="vimeo && !g.limitedCountry" style="width: 100%;">
											<div style="position: relative; overflow:hidden; padding-top: 56.25%; width: 100%">
												<iframe
													style="position:absolute;top:0;left:0;width:100%;height:100%"
													v-if="showmodal"
													:src="'https://player.vimeo.com/video/' + vimeo"
													frameborder="0" allow="autoplay; fullscreen"
													allowfullscreen></iframe>
											</div>
										</div>
										<h3 v-if="link">Visit Demo:</h3>
										<div style="width: 100%;text-align: center;">
											<a v-if="link" style="font-size: 2em;" :href="link" target="_blank">&gt;&gt; Join Now &lt;&lt;</a>
										</div>
									</div>
								</div>

							</div>
							<unlock></unlock>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" tabindex="0" @click="closeModal()">Close</button>
						</div>


					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

	import unlock from "../core/components/unlock.vue";


	export default {
		name: 'videomodal',
		props: {
			sentry: Object,
		},
		components: {
			unlock: unlock,
		},
		data: function(){
			return {
				showmodal: false,

				title: "",
				name: "",
				time: "",
				affiliation: "",

				logo: "",
				link: "",
				teaser: "",
				teaserSelfHosted: "",
				vimeo: "",
				ieeeID: "",
				selfHostedFilename: "",
				lang: "",
			};
		},
		created: function(){

		},
		computed: {

		},
		methods: {
			openModal: function(){
				let vid = this.sentry;

				// Title
				let title = "Video";
				let matches = vid.main.match(/<span class="title">(.+?)<\/span>/);
				if (matches) title = matches[1];

				this.title = title;
				// Time
				this.time = (new Date(this.sentry.time)).toLocaleString('en-gb', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });

				// Affiliation
				let affiliation = "";
				matches = vid.main.match(/<span class="aff">(.+?)<\/span>/);
				if (matches) affiliation = matches[1];
				this.affiliation = affiliation;

				// Name
				let name = "";
				matches = vid.main.match(/<span class="name">(.+?)<\/span>/);
				if (matches) name = matches[1];
				this.name = name;
				if (vid.main.toLowerCase().includes("panel discussion")){
					this.name = vid.main.replace(/<span class="title">(.+?)<\/span>/,"");
				}

				this.logo = vid.logo;
				this.link = vid.link;


				// Teaser
				this.teaser = vid.teaser; // Teaser on Vimeo
				this.teaserSelfHosted = vid.teaserSelfHosted;

				// FUll Video Paths
				this.vimeo = vid.vimeo;
				this.ieeeID = vid.ieeeTVid;
				this.selfHostedFilename = vid.selfHostedFilename;
				//this.self
				// Language
				this.lang = vid.lang;

				this.showmodal = true;

				document.body.classList.add("modal-open");

				let self = this;
				this.$nextTick(()=>{
					setTimeout(()=>self.$refs["modal-backdrop"].classList.add("in"),50);
				});

				document.addEventListener('keydown',this.handleKey);

			},
			handleKey: function(e){
				if (e.key == "Escape") this.closeModal();
			},

			closeModal: function () {
				this.showmodal = false;
				// this.$refs["speaker-modal"].style.display = "none";
				this.$refs["modal-backdrop"].classList.remove("in");
				document.body.classList.remove("modal-open");
				document.removeEventListener('keydown',this.handleKey);
			},
		},
	}

</script>

<style lang="less">

.modal-dialog.video-modal{
	.title{
		margin-top: 0.5em;
		font-size: 1.5em;
		padding-bottom: 8px;
		@media (max-width: 500px) {
			font-size: 1.2em;
		}
		border-bottom: 2px solid #e5e5e5;
		padding-bottom: 3px;

		padding-right: 1.3em;


		&::before{
			display: inline-block;
			margin-right: 0.5em;
			content: "\f008";
			font-family: "FontAwesome";
		}
	}
	.time{
		margin-top: 0.3em;
		color: gray;
		margin-bottom: 1em;
	}

	h3{
		font-size: 16px;
	}

	.info{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;

		padding: 10px;
		margin-top: 0.5em;
		border-radius: 5px;
		// box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
		box-shadow: 0 0 1px 2px #18bc9c8c;

		@media (max-width: 500px) {
			flex-direction: column;
			align-items: flex-start;

		}

		flex-wrap: nowrap;
		.text{
			flex: 10 1 auto;
			.presenter,.aff{
				//margin-left: 1.5em;
			}
			.aff{
				font-weight: bold;
			}
		}

		.logo{
			flex: 0 0 auto;
			margin-left: 1em;
			img{
				max-height: 5em;
				max-width: 8em;
			 }
			@media (max-width: 500px){

				margin-top: 1em;
				//margin-bottom: 0.5em;
				margin-left: 1.5em;
			}
		}
	}

	.language{
		display: block;
		margin-top: 0.5em;
		color: gray;
		&::before{
			content: "\f028";
			font-family: "FontAwesome";
			margin-right: 0.5em;
		}
	}

	iframe,video{
		border: 1px solid #d5d5d5;
	}

}

.live,.live-interactive{
	display: block;
	color: #f5205a;
	margin-top: 0.4em;
	font-size: 0.9em;
	//font-weight: bold;
	&::before{
		content: "\f03d";
		font-family: "FontAwesome";
		margin-right: 0.5em;
	}

}
.live-interactive::after{
	content: "Live / Interactive"
}
.live::after{
	content: "Live"
}



</style>
