export var legal = `
<p>The <a href="https://tu-dresden.de/impressum">Legal Notice of TU Dresden</a> applies with the following amendments:</p>

<h4>RESPONSIBILITIES</h4>

<p>
If you have any questions regarding content, please contact:<br>
<p style="margin-left: 20px">Prof. Dr.-Ing. Rafael F. Schaefer<br>
Technische Universität Dresden<br>
01062 Dresden<br>
Email: <a href="mailto:rafael.schaefer@tu-dresden.de">rafael.schaefer@tu-dresden.de</a><br>
Tel.: +49 351 463-32239
</p>
</p>

<h4>Technical Implementation</h4>

<p>
Technische Universität Dresden<br>
Institute of Communication Technology<br>
Chair of Information Theory and Machine Learning<br>
01062 Dresden<br>
<br>
Head: Prof. Dr.-Ing. Rafael F. Schaefer<br>
Email: <a href="mailto:wsa2024@tu-dresden.de">wsa2024@tu-dresden.de</a>
</p>

<h4>DATA PROTECTION DECLARATION</h4>

<p>TU Dresden processes personal data for the use of the public website <strong>wsa2024.org</strong>. This personal data pertains to cookies only, which are used exclusively for providing this service. In particular, this means that this website uses no tracking cookies to record or analyze user movement and behavior on our website. 
</p>

<h5>Legal Basis</h5>

<p>The legal basis for this is Art. 6 para. 1 letter f GDPR.</p>

<h5>Rights of Data Subjects </h5>

<ul>
    <li> You have the right to obtain information from TU Dresden on the data processed concerning you and/or to request the correction of inaccurate data. </li>
    <li> You have the right to erasure and restriction of processing as well as the right to object to the processing. </li>
    <li> You can contact TU Dresden's Data Protection Officer at any time:<br><br>
    <p style="margin-left: 20px">Technische Universität Dresden<br>
    Data Protection Office<br>
    01062 Dresden<br>
    Tel.: +49 351 463 32839<br>
    Fax: +49 351 463 39718<br>
    <a href="mailto:informationssicherheit@tu-dresden.de">informationssicherheit@tu-dresden.de</a><br>
    <a href="https://tu-dresden.de/informationssicherheit">https://tu-dresden.de/informationssicherheit</a><br><br></p>
    </li>
    <li> You also have the right to appeal to the supervisory authority if you believe that the processing of data concerning your person does not comply with the law. The supervisory authority for data protection is:<br><br>
    <p style="margin-left: 20px">Saxon Data Protection Officer<br>
    Dr. Juliane Hundert<br>
    Devrientstraße 5<br>
    01067 Dresden<br>
    Email: <a href="mailto:post@sdtb.sachsen.de">post@sdtb.sachsen.de</a> <br>
    Phone: + 49 (0) 35185471 101<br>
    <a href="www.datenschutz.sachsen.de">www.datenschutz.sachsen.de</a></p>
    </li>
</ul>
`
