<template>
	<!-- Organizers -->
	<div class="container">
		<div class="flex-grid text-center">
			<template v-for="(member,index) in g.organizers">
				<div class="committee-thumb text-center" :key="index">
					<div class="member-role" :title="member.role" v-html="member.role"></div>
					<img :src="'img/committee/' + member.portrait" :alt="member.name" />
					<div class="member-caption">
						<strong v-html="member.name"></strong><br><span v-html="member.affiliation"></span>
					</div>
				</div>
			</template>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'organizers',
		// props: {
		// 	organizers: Array,
		// },
		data: function(){
			return {

			};
		},
		created: function(){
		},
		methods: {

		},
	}

</script>

<style lang="less">
	.member-role{
		font-weight: 800;
		overflow: hidden;
	}
</style>
