// CONTENT.JS
//
// This File contains the contents of the website!
// Changes in this file only take effect, after: npm run build
//
// The website content is specified as a javascript object literal below.
// The "content"-fields, below can be an object or an array of objects. Content objects can be nested.
//
// Hint: Use ` ` for multi-line strings. (This is what requires the build step - as it is not supported by all browsers)
//
// Supported Objects in content fields:
//
// // Simple HTML:
// 	{
//		type: "html"
//		value: "<p>some html<p>"
// 	}
// // Expandable: Allows to show/hide content by clicking on title
// 	{
// 		type: "expandable",
// 		title: "The Title",
// 		content: []
// 	}
// // Cards: Allows to display content in cards
// 	{
// 		type: "cards", // use "cards-light" for dark background
// 		cards:
// 		[
// 			{
// 				card: "1",
// 				content: []
// 			},
// 			{
// 				card: "2",
// 				content: []
// 			},
// 		]
// 	}
// // Table:
// 	{
// 		type: "table",
// 		widths: ['4em','auto','auto','auto'], // column widths
// 		// rowStyle: ["font-weight: bold;font-size: 1.2em", "font-weight: bold; background-color: #2B628D; color: white","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;","font-size: 0.9em;"],
// 		// colStyle: ["font-weight: bold"],
// 		// cellStyle: "",

// 		// rowClass: [],
// 		// colClass: [],
// 		// cellClass: "",

// 		table: [
// 			["cell11","cell12","cell13","cell14"], // row 1
// 			["cell21","cell22","cell23","cell24"], // row 2
// 		]
// }
// Components:
// 	{
// 		type: "component:co-sponsors" / "component:sponsors" / "component:speakers" / "component:organizers"
// 	}

// Styles AND Classes:
// Except for Components (i.e., type: "component:..."). All content objects support fields "class" & "style" to add specific classes and styles to the contents.

// Timing:
// Every content object or property (simple properties can be made objects as {value: string})
// can have "from" and / or "till" properties that specify when they are to be displayed.
// If there are multiple choices depending on the date. Arrays of {value: x, from:y, till:z} are possible. The first match will be displayed.
// Time Format is: 2020-09-29T14:23:00 // all time must be specified in CEST = UTC+2
// Examples:
// header:{
//	 message: [{value: "Register Now", till:"2020-09-29T08:00"},{value: "Ongoing",from:"2020-09-29T08:00"}]
// }
//

// Icons: https://fontawesome.com/v5.3.1/icons?d=gallery

export var content = {

	copyrightText: "Copyright © Chair of Information Theory and Machine Learning, TU Dresden - Website template created by Fabian Diehm (Barkhausen Institut) - ",

	menu: [
		{ text: "Home", target: "page-top" },
		// { text: "CfP", target: "callforpapers" },
		{ text: "Authors", target: "authors" },
		{ text: "Registration", target: "registration" },
		{ text: "Deadlines", target: "deadlines" },
		{ text: "Demos", target: "demos" },
		// { text: "Keynotes", target: "keynotes" },
		{ text: "Program", target: "program" },
		{ text: "Venue", target: "venue" },
		{ text: "Committee", target: "committee" },
	],

	header: {
		preTitle: "27th International",
		title: "Workshop on Smart Antennas",
		// postTitle: "WSA 2024",
		dates: "March 17&ndash;19, 2024 | Dresden, Germany",
		// location: "Dresden, Germany",
		buttons: [
			{
				text: "Add to Calendar",
				icon: "far fa-calendar-alt",
				target: "wsa-2024-dresden.ics",
				till: "2024-03-16T01:00:00"
			},
			// {
			// 	text: "Call for Papers",
			// 	icon: "fa fa-file-alt",
			// 	target: "#callforpapers",
			// 	till: "2024-03-16T01:00:00",
			// },
			{
				text: "Register Now",
				icon: "fa fa-pen-nib",
				target: "#registration",
				till: "2024-03-16T01:00:00",
			},
			{
				text: "Deadlines",
				icon: "far fa-calendar",
				target: "#deadlines",
				till: "2024-03-16T01:00:00",
			},
			{
				text: "Become a Patron",
				icon: "far fa-handshake",
				target: "PatronageBrochure_WSA2024.pdf",
				till: "2024-03-16T01:00:00",
			}
		],
	},

	sections: [

		{
			name: "about",
			background: "light",
			content: [

				// { // Initial message
				// 	type: "html",
				// 	from: "2023-07-26T19:15:00",
				// 	value: `
				// 	<div style="margin: -1em 0 1em 0; display: flex; justify-content: center; align-items: center; flex-direction: column; border-bottom: 1px solid lightgray;">

				// 		<div style="font-size: 1.5em; padding: 1em 2em;">
				// 		<p><strong>&gt;&nbsp;Under construction...&nbsp;&lt;</strong></p>
				// 		<div>
				// 	<div>
				// 	`

				// },
				{
					type: "html",
					// <p>IEEE 5G++ Summit Dresden has become a prominent annual fixture on the road to 6G. The well-known high-impact conference brings together industry leaders, innovators, and researchers from industry and academia to exchange ideas that will help to drive standards and rapid deployment of 5G++ technologies. The IEEE 5G++ Summit Dresden takes a holistic approach to 5G++ system design, ranging from silicon hardware, wireless interfaces, networks, edge clouds all the way up to Tactile Internet applications.</p>
					value: `
					<p>The <b>27th International Workshop on Smart Antennas (WSA 2024)</b> provides a forum for presenting the most recent research on smart antennas and related theoretical and technical aspects in modern wireless communication systems. The objective is to continue, accelerate, and broaden the momentum already gained through a series of workshops which started in 1996.
					</p>
					<p>We very much look forward to your participation!</p>
					<p>For any questions regarding the conference, please contact <a href="mailto:wsa2024@tu-dresden.de">wsa2024@tu-dresden.de</a>.</p>
					`,
				},

				{
					type: "html",
					value: `
					<cosponsors></cosponsors>
					`,
				},

				{
					type: "html",
					value: `
					<p>Are you interested in becoming a patron? Check out our <a href="PatronageBrochure_WSA2024.pdf" target="_blank">Patronage Brochure</a>.</p>
					`,
				},
			],
		},

		// {
		// 	name: "callforpapers",
		// 	title: "Call for Papers",
		// 	background: "dark",
		// 	content: [
		// 		{
		// 			type: "html",
		// 			value: `
		// 			<p>Download the <a href="CallForPapers_WSA2024.pdf" target="_blank">Call for Papers</a> as PDF file.</p>
		// 			`,
		// 		},
		// 	],
		// },

		{
			name: "authors",
			title: "Authors",
			background: "dark",
			content: [
				{
					type: "html",
					value: `

					<p>Download the <a href="CallForPapers_WSA2024.pdf" target="_blank">Call for Papers</a> as PDF file.</p> 
					<br>
					<p>The conference comprises both oral and poster presentations. All accepted papers must be presented in-person at the conference. Exceptions may be granted by the General Chairs prior to the event. We reserve the right to exclude a paper from distribution after the conference, including publication in the IEEE Xplore Digital Library, if the paper is not presented by the author at the conference.</p>
					<p>Papers can be submitted in extended abstract form (at least 2 pages, A4 format), with sufficient detail to allow the review. Camera-ready papers are limited to a maximum of 8 pages, A4, two-column IEEE conference format. Page numbers, headers and footers are not allowed. All fonts have to be embedded in the PDF file. LaTeX users should ensure to use <TT>IEEEtran</TT> style in <TT>conference</TT> and <TT>comsoc</TT> mode. Please refer to the <a href="https://www.ieee.org/conferences/publishing/templates.html">IEEE Manuscript Template for Conference Proceedings guidelines</a> when formatting both the initial and the final camera-ready submission. The final manuscript must be certified by IEEE PDF eXpress.</p>
					<p>Papers are reviewed on the basis that they do not contain plagiarized material and have not been submitted to any other conference at the same time (double submission). These matters are taken very seriously and we will take action against any author who engages in either practice. For more information, please refer to the <a href="https://www.ieee.org/publications/rights/plagiarism/plagiarism.html">IEEE Policy on Plagiarism</a> and the <a href="https://www.ieee.org/publications/rights/multi-sub-guidelines-intro.html">IEEE Policy on Double Submission</a>.</p>
					<p>All papers must be submitted in PDF via <a href="https://edas.info/N31647">EDAS</a>.</p>
					<br><br>
					<h2>Instructions for Final Manuscript Submission</h2>
					<br>
					<p>Please make sure to complete all the steps for the final manuscript submission before the <strong>deadline of February 11, 2024</strong>.</p>
					<p>No paper will be published without the submitted IEEE Copyright Form or <a href=#registration>registration</a> to the conference.</p>
					<p>For your convenience, the copyright form is made available digitally through EDAS. To access the IEEE eCopyright Form, click the "Add" icon in the "Copyright" row within your paper record. Please use the booking number from Eventbrite as registration code in EDAS and specify who will be presenting the paper in the "Presented by" row. Click that "Add" icon and select the presenter.</p>
					<p>The final manuscript of your paper should account for all the critical reviewers' feedback.</p>
					<p>The final manuscript has to be uploaded as PDF file and must meet IEEE requirements. This includes checking the file format and the margins, and ensuring that all fonts are embedded. The PDF file must not contain form fields, scanned images, bookmarks, cross-references or hyperlinks. Please do not use LaTeX packages such as <TT>hyperref</TT>.</p>
					<p>Please use <a href="https://ieee-pdf-express.org/">IEEE PDF eXpress</a> to check and convert the final manuscript of your paper for IEEE Xplore compliance before submitting it through EDAS. Log in at <a href="https://ieee-pdf-express.org/">https://ieee-pdf-express.org/</a> and enter <strong>61681X</strong> for the Conference ID. If you use IEEE PDF eXpress for the first time, please go to <a href="https://ieee-pdf-express.org/account/signup/">https://ieee-pdf-express.org/account/signup/</a>. In both cases follow the given instructions for the next steps. You will receive an e-mail with the checked and converted PDF file attached. The e-mail will show if your PDF file passed or failed. If the PDF file passed the check, you can proceed to upload the converted PDF file in EDAS.</p>
					<p>For questions regarding IEEE PDF eXpress, you can contact the <a href="https://supportcenter.ieee.org/app/answers/list/p/12">Publication Support Center</a>.<br>Please ensure to quote your abstract ID from IEEE PDF eXpress in all correspondence.</p>
					`,
				},
			],
		},

		{
			name: "registration",
			title: "Registration",
			content: [
				{
					type: "html",
					till: "2024-03-21T18:00:00",
					value: `
					<p style="margin-top: -0em; font-size: 1.5em;">Registration is now available. Please register <a style="font-weight: bold" href="https://www.eventbrite.de/e/796041520617" target="_blank">here</a>&nbsp;.</p>
					<p><a href="GeneralTermsAndConditions_WSA2024.pdf" target="_blank">Terms and Conditions</a> apply.</p> 
					<p>To all authors: Please follow the link provided to you in the acceptance notification (via EDAS) to register for the conference.</p>
					`,
				},
				{
					type: "html",
					from: "2024-03-21T18:00:00",
					value: `
					<p><strong>Note:</strong> Online registration is not available anymore.</p>
					`
				},

				{
					type: "html",
					value: `
					<h2>Prices</h2>
					`,
				},
				{
					type: "table",
					widths: ["12em", "auto", "auto", "auto"],
					rowStyle: ["font-weight: bold; border-bottom: 1px solid gray; background-color: rgb(14, 83, 116); color: white;"],
					colStyle: ["font-weight: bold;"],
					cellStyle: "font-size: 1.2em;",

					table: [
						[``, "Standard", "Late", "Last Minute / On-Site"],
						["Author", "{{getPrice(650)}} €", "{{getPrice(725)}} €", "{{getPrice(800)}} €"],
						["Participant (Non-Author)", "{{getPrice(600)}} €", "{{getPrice(675)}} €", "{{getPrice(750)}} €"],
						["Full-Time Student*", "{{getPrice(250)}} €", "{{getPrice(300)}} €", "{{getPrice(350)}} €"],
						["Social Event only**", "{{getPrice(100)}} €", "{{getPrice(100)}} €", "{{getPrice(100)}} €"]
					],
				},
				{
					type: "html",
					value: `
					<div style="font-size: 0.9em">
						<p> Prices including 19% VAT.</p>
						<p> All ticket categories (except for "Social Event only") include the participation in the full conference at TU Dresden from March 17 to March 19, coffee breaks and lunch catering, and the participation in the welcome reception on Sunday evening (March 17) and the social event on Monday evening (March 18) at Schloss Albrechtsberg. </p>
						<p>* An enrollment certificate is required. Scientific employees cannot register in this category and will have to pay full prices.</p>
						<p>** Includes only the participation in the social event on Monday evening (March 18) at Schloss Albrechtsberg.</p>
					</div>
					`,
				},
				{
					type: "html",
					// value: `
					// <h2 style="margin-bottom: 1em;">Prices</h2>
					// <label class="check-label" style="margin-top: 0; font-size: 1.3em; cursor: pointer;"><input type="checkbox" v-model="l.includeVat">Include VAT in prices</label>
					// <div style="margin-bottom: -1em"></div>
					// `,
					value: `
					<h2>Registration Deadlines</h2>
					`,
				},
				{
					type: "html",
					value: `
					<div style="display: flex; align-items: center; justify-content: center;">
					<div style="font-size: 1.4em; margin-bottom: 1em; line-height: 1.8em; border: 0px solid white; border-radius: 4px; width: fit-content; padding: 1em;">
					<ul style="text-align: left; list-style: none">
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 400px"><strong>Standard:</strong></span> <span style="display: inline-block; margin-left: 1em;">February 23, 2024 (12:00 CEST)</span></div></li>
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 400px"><strong>Late:</strong></span> <span style="display: inline-block; margin-left: 1em;">March 14, 2024 (12:00 CEST)</span></div> </li>
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 400px"><strong>Last Minute / On-Site:</strong></span> <span style="display: inline-block; margin-left: 1em;">March 17, 2024 (12:00 CEST)</span></div>
					</ul>
					</div>
					</div>
					`,
				}


			],
		},

		{
			name: "deadlines",
			title: "Deadlines",
			background: "dark",
			content: [
				{
					type: "html",
					value: `
					<div style="display: flex; align-items: center; justify-content: center;">
					<div style="font-size: 1.4em; margin-bottom: 1em; line-height: 1.8em; border: 0px solid white; border-radius: 4px; width: fit-content; padding: 1em;">
					<ul style="text-align: left; list-style: none">
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 400px"><strong>Extended Abstract Submission<br> (final extended deadline):</strong><br><br></span> <span style="display: inline-block; margin-left: 1em; color:#FF0000;"> <s>November 5, 2023</s><br><s>November 27, 2023</s><br>December 8, 2023</span></div> 
					</li>
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 400px"><strong>Acceptance Notification:</strong></span> <span style="display: inline-block; margin-left: 1em;">January 19, 2024</span></div> </li>
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 400px"><strong>Camera-Ready Paper Submission:</strong></span> <span style="display: inline-block; margin-left: 1em;">February 11, 2024</span></div>
					</ul>
					</div>
					</div>
					`,
					// <p> All accepted and presented publications will be submitted for inclusion into IEEE Xplore subject to meeting IEEE Xplore's scope and quality requirements. </p>
				},
			],
		},

		{
			name: "demos",
			title: "Demos",
			background: "light",
			content: [
				{
					type: "html",
					value: `
					<p>WSA 2024 will feature an interactive exhibition space where demonstrators such as wireless simulation tools, testbeds and prototypes will be presented. We are glad to welcome the following exhibitors to the conference:</p>
					<exhibitors></exhibitors>
					`,
				},
			],
		},

		// {
		// 	name: "keynotes",
		// 	title: "Keynotes",
		// 	background: "dark",
		// 	content: [
		// 		{
		// 			type: "html",
		// 			value: `
		// 			<p>To be announced soon.</p>
		// 			`,
		// 			// type: "component:speakers",
		// 		},
		// 	],
		// },

		{
			name: "program",
			title: "Program",
			background: "dark",
			content: [
				// {
				// 	type: "html",
				// 	style: "font-size: 1.2em",
				// 	value: `
				// 	<p>Download the program <a href="Program_WSA2024.pdf" target="_blank">at a glance</a> or <a href="Program_WSA2024_detailed.pdf" target="_blank">in more detail</a>.</p> 
				// 	`,
				// },
				{
					type: "component:program"
				},
				{
					type: "html",
					style: "font-size: 1.2em",
					value: `
					<h2>Social Program</h2>
					<br>
					<ul style="text-align: left; list-style: none; font-size: 1.2em">
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 250px"><strong>Welcome Reception:</strong></span> <span style="display: inline-block; margin-left: 1em;">Sunday, March 17, 18:00 at Heinz Schönfeld Lecture Hall, TU Dresden</span></div> </li>
					<li> <div style="opacity: 1;"><span style="display: inline-block; width: 250px"><strong>Social Event:</strong></span> <span style="display: inline-block; margin-left: 1em;">Monday, March 18, 19:00 at Schloss Albrechtsberg, Dresden</span></div>
					</ul>
					`,
				},
				{
					type: "html",
					value: `
					<h2>Invited Speakers</h2>
					`,
				},
				{
					type: "component:speakers",
				},
				// {
				// 	type: "html",
				// 	value: `
				// 	<p>To be announced soon.</p>
				// 	`,
				// },
			],
		},

		{
			name: "venue",
			title: "Venue",
			//background: "venue",
			background: "light",
			content: [
				{
					type: "html",
					style: "font-size: 1.2em",
					value: `
					<p><strong>Conference Venue:</strong> Barkhausen Building, TU Dresden </p>

					<div class="figure">
					<figure style="display: inline-block; margin: 20px;">
					  	<img src="img/barkhausenbau-schoenfeld-hoersaal_copyright.png" alt="Schönfeld Lecture Hall, TU Dresden" style="width:450px; float:left;"> <figcaption>Venue: Heinz Schönfeld Lecture Hall, Barkhausen Building<br><a href="https://www.google.com/maps/place/Sch%C3%B6nfeld-H%C3%B6rsaal+BAR%2FSCH%C3%96%2FE/@51.026274,13.722934,17z/data=!3m1!4b1!4m6!3m5!1s0x4709c597e9151aa5:0xe97f27e659db5ba8!8m2!3d51.026274!4d13.722934!16s%2Fg%2F11js5g8crt?entry=ttu" target="_blank">Directions</a></figcaption>
					</figure>

					<figure style="display: inline-block; margin: 20px;">
						<img src="img/schloss-albrechtsberg-elbseite_new_copyright.png" alt="Schloss Albrechtsberg" style="width:450px; float:left;"> <figcaption>Social Event: Schloss Albrechtsberg, Dresden<br><a href="https://www.google.com/maps/place/Schloss+Albrechtsberg+Dresden/@51.0648073,13.7917242,17z/data=!3m1!4b1!4m6!3m5!1s0x4709c8c50fd7e651:0xf16a629a52ec3c01!8m2!3d51.064804!4d13.7942991!16s%2Fm%2F0g9vdf3?entry=ttu" target="_blank">Directions</a></figcaption>
					</figure>
				  	</div>

					<p><strong>Accommodation:</strong> find a hotel via <a href="https://www.hrs.com/list?location=%4051026166%2C13722738&perimeter=10&showMap=true&orderBy=DistanceToDestination&startDateDay=17&startDateMonth=3&startDateYear=2024&endDateDay=19&endDateMonth=3&endDateYear=2024&language=de&currency=EUR&rooms=1&numberOfAdults%5B0%5D=1&type%5B0%5D=SINGLEROOM&adults=1&children=0&singleRooms=1&doubleRooms=0" target="_blank">hrs.com</a> or <a href="https://www.booking.com/searchresults.html?label=gen173nr-1FCAEoggI46AdIM1gEaDuIAQGYATG4ARfIAQ_YAQHoAQH4AQKIAgGoAgO4AoHP8q0GwAIB0gIkMjE4OTNjM2UtOGMxOS00ODhmLThmM2ItYjIyMGEyZTUyOTM02AIF4AIB&aid=304142&ss=Georg-Schumann-Stra%C3%9Fe+13%2C+01069+Dresden%2C+Germany&ssne=Georg-Schumann-Stra%C3%9Fe+13%2C+01069+Dresden%2C+Germany&ssne_untouched=Georg-Schumann-Stra%C3%9Fe+13%2C+01069+Dresden%2C+Germany&lang=en-us&src=searchresults&latitude=51.026166&longitude=13.7227389&checkin=2024-03-17&checkout=2024-03-19&group_adults=1&no_rooms=1&group_children=0&nflt=oos%3D1#map_closed" target="_blank">booking.com</a></p>
					`
				}
			],
		},

		{
			name: "committee",
			title: "Committee",
			background: "dark",
			content: [
				{
					type: "component:organizers",
				},
			],
		},
	],
};
