<template>
	<!-- Sponsors -->
	<div>
		<div v-for="ptype in filteredTypes" style="margin-bottom: 3em" :key="ptype">
			<h3 v-if="headings" class="text-center">{{ptype}}</h3>
			<div class="sponsors-grid">
				<a v-for="logo in sponsors[ptype]" :href="logo.URL" :key="logo.URL" target="_blank" class="raw">
					<img v-if="!logo.src2x" :src="pictures + logo.src" :style="sponsorStyles(logo)"
					:alt="logo.Name" />
					<img v-if="logo.src2x" :src="pictures + logo.src"
					:srcset="pictures + logo.src + ' 1x,' + pictures + logo.src2x + ' 2x'"
					:style="sponsorStyles(logo)" :alt="logo.Name" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			json: String,
			pictures: String,
			headings: {
				type: Boolean,
				default: true
			},
			cachebreakers: {
				type: Boolean,
				default: false
			},
			include: {
				type: Array,
				default: ()=>[]
			},
			exclude: {
				type: Array,
				default: ()=>[],
			}
		},
		data: function(){
			return {
				sponsors: {},
				sponsorTypes: [],
			};
		},
		created: function(){
			this.reloadJSON();
		},
		computed: {
			filteredTypes: function(){

				let res = [];
				for (let k of this.sponsorTypes) {
					if ( this.include.length > 0 && ! this.include.includes(k) ) continue;
					if ( this.exclude.length > 0 && this.exclude.includes(k) ) continue;
					if (this.sponsors[k].length>0) res.push(k);
				}
				return res;
			}
		},
		methods: {
			reloadJSON: async function () {
				let self = this

				console.log( "reloading Sponsors" )
				let url = self.json + "?" + Date.now()
				let response = await fetch( url )
				let data = await response.json()

				self.addData( "sponsors" )( data );

			},
			addData: function (field) {
				let self = this;
				return function (data) {
					if ( self.cachebreakers ) {
						// Add Cache-Breakers for CMS
						for (var t in data) {
							if ( t == "order" ) continue;
							for (var s of data[t]) {
								s.src = s.src + "?" + Date.now();
								s.src2x = s.src2x + "?" + Date.now();
							}
						}
					}
					self.$set(self,'sponsors',data);
					if ( data.order ) self.$set( self,'sponsorTypes', data.order );
					else self.$set(self,'sponsorTypes',Object.keys(data));

				};
			},
			cmsGotoPage: function (page, goToID) {
				if (window.parent.cms) window.parent.cms.gotoPage(page, goToID);
			},
			sponsorStyles: function (sponsor) {
				var res = {};
				res["width"] = sponsor.width + "px";
				res["max-width"] =
				"calc(100% - " +
				(parseInt(sponsor.marginLeft ? sponsor.marginLeft : 20) +
				parseInt(sponsor.marginRight ? sponsor.marginRight : 20)) +
				"px)";
				if (sponsor.marginLeft)
				res["margin-left"] = sponsor.marginLeft + "px";
				if (sponsor.marginRight)
				res["margin-right"] = sponsor.marginRight + "px";
				if (sponsor.marginTop) res["margin-top"] = sponsor.marginTop + "px";
				if (sponsor.marginBottom)
				res["margin-bottom"] = sponsor.marginBottom + "px";
				return res;
			},
		},
	}

</script>

<style lang="less">
	h3 {
		margin-top: 2.5em;
		margin-bottom: 1.5em;
	}
</style>
