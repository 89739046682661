<template>
	<div style="margin-bottom: 2em">
		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://www.unibw.de/" target="_blank" class="raw"><img
			style="width: 300px; margin: 2.5em; max-width: calc(100% - 1.6em)"
			src="img/exhibitors/uni-bundeswehr-muenchen-logo.svg"
			class="img-responsive" alt="Universität der Bundeswehr München"></a>
		<a href="https://www.iis.fraunhofer.de/" target="_blank" class="raw"><img
			style="width: 300px; margin: 2.5em; max-width: calc(100% - 1.6em)"
			src="img/exhibitors/fraunhofer-iis-logo.svg" class="img-responsive" alt="Fraunhofer IIS"></a>
		<a href="https://www.rohde-schwarz.com/" target="_blank" class="raw"><img
			style="width: 300px; margin: 2.5em; max-width: calc(100% - 1.6em)"
			src="img/exhibitors/rohde-und-schwarz-logo.svg" class="img-responsive" alt="Rohde & Schwarz"></a>
		</div>

		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://www.6gem.de/" target="_blank" class="raw"><img
			style="width: 200px; margin: 2.5em; max-width: calc(100% - 1.6em)"
			src="img/exhibitors/6gem-logo.svg" class="img-responsive" alt="6GEM"></a>
		<a href="https://www.barkhauseninstitut.org/" target="_blank" class="raw"><img
			style="width: 250px; margin: 2.5em; max-width: calc(100% - 1.6em)"
			src="img/exhibitors/barkhaus-institut-logo.svg" class="img-responsive" alt="Barkhausen Institut"></a>
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'exhibitors',
	}

</script>

<style lang="less">

</style>
