export var organizers = [

	{
		name: "Prof. Dr. Rafael F. Schaefer",
		affiliation: "TU&nbsp;Dresden",
		portrait: "RafaelSchaefer.jpg",
		role: "General<br>Co-Chair",
	},

	{
		name: "Prof. Dr. Gerhard P. Fettweis",
		affiliation: "TU&nbsp;Dresden /<br>Barkhausen Institut",
		portrait: "GerhardFettweis.jpg",
		role: "General<br>Co-Chair",
	},

	{
		name: "Prof. Dr. Wolfgang Utschick",
		affiliation: "TU&nbsp;Munich",
		portrait: "UtschickWolfgang.jpg",
		role: "Honorary<br>Co-Chair",
	},

	{
		name: "Prof. Dr. Josef Nossek",
		affiliation: "TU&nbsp;Munich",
		portrait: "NossekJosef.jpg",
		role: "Honorary<br>Co-Chair",
	},

	{
		name: "Prof. Dr. Peter Rost",
		affiliation: "Karlsruhe Institute of Technology",
		portrait: "PeterRost.jpg",
		role: "TPC<br>Co-Chair",
	},
 
	{
		name: "Prof. Dr. Norman Franchi",
		affiliation: "FAU Erlangen-Nürnberg",
		portrait: "NormanFranchi.jpg",
		role: "TPC<br>Co-Chair",
	},

	{
		name: "Dr. Martin Mittelbach",
		affiliation: "TU&nbsp;Dresden",
		portrait: "MartinMittelbach.jpg",
		role: "Organizing<br>Co-Chair",
	},

	{
		name: "Daniel Seifert",
		affiliation: "TU&nbsp;Dresden",
		portrait: "DanielSeifert.jpg",
		role: "Organizing<br>Co-Chair",
	},

	{
		name: "Prof. Dr. Onur Günlü",
		affiliation: "Linköping&nbsp;University",
		portrait: "OnurGunlu.png",
		role: "Exhibition<br>Chair",
	},

	{
		name: "Dr. Rico Radeke",
		affiliation: "TU&nbsp;Dresden /<br>5G Lab Germany",
		portrait: "RicoRadeke.jpg",
		role: "Finance<br>Chair",
	},

	{
		name: "Thomas Uhle",
		affiliation: "TU&nbsp;Dresden",
		portrait: "ph.jpg",
		role: "Publication<br>Chair",
	}

];
