export var accessibility = `

<p>This accessibility statement applies to <strong>wsa2024.org</strong>. This website is run by the Chair of Information Theory and Machine Learning, TU Dresden.</p>

<p>The Chair of Information Theory and Machine Learning is committed to making its websites and mobile applications accessible, in accordance with “Website Accessibility Act” BfWebG (Barrierefreie-Websites-Gesetz) and the “Accessible IT Ordinance” BITV 2.0 (Barrierefreie-Informationstechnik-Verordnung), implementing EU Directive 2016/2102.</p>

<h4>Preparation of This Accessibility Statement</h4>

<p>This statement was prepared on 14.08.2023 and last reviewed on 22.08.2023. The basis for issuing this accessibility statement is a self-assessment carried out by TU Dresden.</p>

<h4>Compliance Status</h4>

<p>This website is fully compliant with the requirements of the BfWebG in conjunction with BITV 2.0.</p>

<h4>Feedback and Contact Information</h4>

<p>If you notice any shortcomings concerning accessible design, please contact <a href="mailto:wsa2024@tu-dresden.de">wsa2024@tu-dresden.de</a>.</p>

<h4>Enforcement Procedures</h4>

<p>If we do not process your feedback satisfactorily from your point of view, you can contact the Enforcement Body of Saxony:<br>
<p style="margin-left: 20px">Office of the Commissioner of the Saxon State Government for the Affairs of Persons with Disabilities<br>
Albertstraße 10<br>
01097 Dresden<br><br>

Postal address: Archivstraße 1, 01097 Dresden<br>
Email: <a href="mailto:info.behindertenbeauftragter@sk.sachsen.de">info.behindertenbeauftragter@sk.sachsen.de</a><br>
Telephone: +49 351 564-12161<br>
Fax: +49 351 564-12169<br>
Website: <a href="https://www.inklusion.sachsen.de">https://www.inklusion.sachsen.de</a>
</p>

`
