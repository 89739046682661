<template>

	<div class="resp-header">
		<div>

			<Transition name="swap" mode="out-in">
				<span v-if="old" class="num el" key="8th">8<span class="hochgestellt">th</span></span>
			</Transition>
			<Transition name="text1" mode="out-in">
				<span v-if="old" class="el text" id="text11" key="old"><strong> IEEE</strong></span>
				<span v-else key="new" id="text12" class="el text"><strong> IEEE</strong></span>
			</Transition>
		</div>
		<div>

			<Transition name="swap" mode="out-in" key="5G">
				<span v-if="old" class="gen el" key="6G"><strong><span style="font-size:1.09em">5</span>G</strong><span class="bigger"><span class="hochgestellt">+<span class="hochgestellt">+</span></span></span></span>
				<span v-else class="gen el"><strong><span style="font-size:1.09em">6</span>G</strong></span>
			</Transition>
			<Transition name="text2" mode="out-in">
				<span v-if="old" id="text21" class="el text" key="old"><strong> SUMMIT DRESDEN</strong></span>
				<span v-else key="new" class="el text"><strong> SUMMIT DRESDEN</strong></span>
			</Transition>
		</div>
	</div>

</template>

<script>

export default {
		name: 'HeaderAnimation',
		data: function(){
			return {
				old: true

			};
		},

		mounted: function(){
			let self = this
			setTimeout(() => {
				self.old = false
			}, 1000);
		},
		methods: {

		}
	}


</script>

<style>

.el {
	transition: all 1s ease;
	opacity: 1;
	display: inline-block;
	transform: translate(0,0);
}

.el.text {
	margin-left: 0.3em;
}

#text11 {
	margin-left: 1em;
}
#text21 {
	margin-left: 1em;
}

.text1-enter{
	transform: translate(0.57em,0);

}
.text2-enter{
	transform: translate(0.37em,0);
}

.swap-enter{
	color: red;
	transform: translate(0,20px)
}

.swap-enter,
.swap-leave-to {
  opacity: 0;
}


.swap-leave-to {
	transform: translate(0,-0.35em)
}

</style>

<style scoped>
	@media screen and (max-width: 599px){
		.resp-header{
			font-size: 3rem;
		}
	}

	/* @media screen and (min-width: 600px) and (max-width: 800px) {
		.resp-header{
			font-size: 2rem;
		}

	} */
	.bigger {
		font-size: 1.6em;
		display: inline-block;
		height: 0;
	}

	.hochgestellt {
		font-size: 0.6em;
		display: inline-block;
		position: absolute;
		bottom: 0.5em;
		margin-right: 0.5em;
	}


</style>
