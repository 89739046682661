<template>
	<div style="margin-bottom: 2em">
		<h3 style="text-align: center; margin: 3em 0 1.2em 0;">Presented by and hosted at</h3>
		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://tu-dresden.de" target="_blank" class="raw"><img
			style="width: 240px; margin: 0.7em 0.7em 0.7em 1.5em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/TUD.png"
			class="img-responsive" alt="TU Dresden"></a>
		<!-- <a href="https://www.ieee.org" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.9em 1em; max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-main.jpg" class="img-responsive" alt="IEEE"></a>
		<a href="https://www.comsoc.org/" target="_blank" class="raw"><img
			style="width: 194px; margin: 0.9em 1em;max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-comsoc.jpg" class="img-responsive" alt="IEEE Communications Society"></a>
		<a href="https://5glab.de" target="_blank" class="raw"><img
			style="width: 240px; margin: 0.7em 0.7em 0.7em 3.5em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/cospon_5Gpp_Lab1x.png"
			srcset="img/sponsors/cospon_5Gpp_Lab1x.png 1x, img/sponsors/cospon_5Gpp_Lab2x.png 2x"
			class="img-responsive" alt="5G Lab Germany"></a> -->
		</div>
		<h3 style="text-align: center; margin: 3em 0 1.2em 0;">Co-Sponsorship by</h3>
		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://www.ieee.org" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.9em 1em; max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-main.jpg" class="img-responsive" alt="IEEE"></a>
		<a href="https://www.comsoc.org/" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.9em 1em; max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-comsoc.jpg" class="img-responsive" alt="IEEE Communications Society"></a>
		<a href="https://5glab.de" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.7em 0.7em 0.7em 0.7em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/cospon_5Gpp_Lab1x.png"
			srcset="img/sponsors/cospon_5Gpp_Lab1x.png 1x, img/sponsors/cospon_5Gpp_Lab2x.png 2x"
			class="img-responsive" alt="5G Lab Germany"></a>
		</div>
		<h3 style="text-align: center; margin: 3em 0 1.2em 0;">Patrons</h3>

		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://www.rohde-schwarz.com/" target="_blank" class="raw"><img
			style="width: 560px; margin: 1em;"
			src="img/sponsors/rohdeschwarz.png" class="img-responsive" alt="Rohde & Schwarz"></a>
		</div>
		<br>
		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://www.ericsson.com/" target="_blank" class="raw"><img
			style="width: 180px; margin: 1em;"
			src="img/sponsors/ericsson.svg" class="img-responsive" alt="Ericsson"></a>
		</div>
		<br>
		<div class="sponsors-grid" style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center; flex-shrink: 1;">
		<a href="https://www.ni.com/" target="_blank" class="raw"><img
			style="width: 440px; margin: 1em;"
			src="img/sponsors/emerson_ni.png" class="img-responsive" alt="NI (now part of Emerson)"></a>
		<!-- <a href="https://www.comsoc.org/" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.9em 1em; max-width: calc(100% - 1.6em)"
			src="img/sponsors/cospon_ieee-comsoc.jpg" class="img-responsive" alt="IEEE Communications Society"></a> -->
		<!-- <a href="https://5glab.de" target="_blank" class="raw"><img
			style="width: 206px; margin: 0.7em 0.7em 0.7em 0.7em; max-width: calc(100% - 0.8em)"
			src="img/sponsors/cospon_5Gpp_Lab1x.png"
			srcset="img/sponsors/cospon_5Gpp_Lab1x.png 1x, img/sponsors/cospon_5Gpp_Lab2x.png 2x"
			class="img-responsive" alt="5G Lab Germany"></a> -->
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'cosponsors',
	}

</script>

<style lang="less">

</style>
